<template>
  <Modal :size="ModalSize.XL" :open="open">
    <template #activator="{ activatorProps }">
      <button
        v-if="socialType === ContentSocialType.FACEBOOK"
        v-bind="activatorProps"
      >
        <slot />
      </button>
      <slot v-else />
    </template>
    <template #header="{ closeModal }">
      <div class="relative">
        <button
          class="absolute right-5 top-5 text-[white] md:text-secondary"
          @click="closeModal"
        >
          <Icon :icon="CloseSvg" />
        </button>
      </div>
    </template>
    <template #content>
      <div
        class="md:grid md:grid-cols-10 -ml-6 -mr-6 md:-ml-8 md:-mr-8 bg-[black] md:bg-[white] h-full"
      >
        <div
          class="h-full w-full col-span-7 bg-[black] flex items-center justify-center"
        >
          <Carousel
            :variant="CarouselVariant.SLIDER"
            :files="carouselFiles"
            :slider-background-variant="SliderBackgroundVariant.BLACK"
            @active-index-update="activeIndex = $event"
          />
        </div>
        <div
          class="absolute bottom-0 text-[white] mb-5 md:static max-h-60 md:max-h-full overflow-y-auto md:h-full w-full md:pt-15 px-5 md:pr-10 col-span-3 md:text-secondary"
        >
          {{ socialFiles[activeIndex].caption }}
        </div>
      </div>
    </template>
    <template #actions></template>
  </Modal>
</template>
<script setup lang="ts">
import { Modal, ModalSize } from '~/components/modals/modal';
import { Icon } from '~/components/icon';
import CloseSvg from '@/assets/icons/close.svg?component';
import { ContentSocialFileDto, ContentSocialType } from '~/api/contents';
import {
  Carousel,
  CarouselVariant,
  SliderBackgroundVariant,
} from '~/components/carousel';

type Props = {
  open?: boolean;
  socialFiles: ContentSocialFileDto[];
  socialType: ContentSocialType;
};

const props = defineProps<Props>();
const activeIndex = ref<number>(0);
const carouselFiles = computed(() =>
  props.socialFiles.map(({ file }) => ({
    id: file.id,
    src: file.url,
  })),
);
</script>
